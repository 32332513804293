import React, { useState } from 'react'
import { Layout, Menu, message } from 'antd'
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  Redirect,
} from 'react-router-dom'
import styles from './index.module.scss'
import logo from '../../assets/logo.png'
import request from '../../utils/request'
import api from '../../api'
import {
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
} from '@ant-design/icons'
import PersonalSettings from './PersonalSettings'
import RolesManagement from './RolesManagement'
import MemberManagement from './MemberManagement'
import MemberApplication from './MemberApplication'
import Productsubscription from './ProductSubscription'
import ApplMemberPage from './ApplMemberPage'
import { MyIcon } from '../../routers'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getMenuOfPersonal, getUserInfo } from '../../store/actions/login'
import {
  getAdmin,
  getCreator,
  getDeploy,
  getCallbackUrl,
} from '../../utils/cookie'
import MyHeader from '../../components/MyHeader'
import {
  getCompany,
  getLogoUrl,
  getSuperGroup,
  getTitleName,
  removeCompany,
  removeOaOrg,
  removeSuperGroup,
  removeToken,
  removeUserStatus,
  setRedirectUrl,
  setSuperGroup,
  setToken,
} from '../../utils/cookie'
import { getLocationOrigin } from '../../utils/util'

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  }
}

function getItems(userInfo) {
  //BOX部署 只有个人设置
  if (getDeploy() === 'BOX') {
    return [
      getItem(
        '个人设置',
        '/saas/personalsettings',
        <MyIcon type="icon-gerenshezhi1" />
      ),
    ]
  }
  //没有企业
  if (!userInfo.co_id) {
    return [
      getItem(
        '个人设置',
        '/saas/personalsettings',
        <MyIcon type="icon-gerenshezhi1" />
      ),
    ]
  }
  //个人版 有个人设置、产品订阅
  if (userInfo?.co_type_code === 'INDIV_ORG') {
    return [
      getItem(
        '个人设置',
        '/saas/personalsettings',
        <MyIcon type="icon-gerenshezhi1" />
      ),
      getItem(
        '产品订阅',
        '/saas/productsubscription',
        <MyIcon type="icon-chanpindingyue" />
      ),
    ]
  }
  //企业版 创建者或者管理员  个人设置、产品订阅、企业管理
  if (getCreator() || getAdmin()) {
    return [
      getItem(
        '个人设置',
        '/saas/personalsettings',
        <MyIcon type="icon-gerenshezhi1" />
      ),
      // getItem(
      //   '账户管理',
      //   '/saas/accountmanagement',
      //   <MyIcon type="icon-zhanghuguanli1" />
      // ),
      getItem(
        '产品订阅',
        '/saas/productsubscription',
        <MyIcon type="icon-chanpindingyue" />
      ),
      getItem(
        '企业管理',
        '/saas/businessmanagement',
        <MyIcon type="icon-qiyeguanli1" />,
        [
          getItem('新成员申请', '/saas/businessmanagement/memberapplication'),
          getItem('成员管理', '/saas/businessmanagement/membermanagement'),
          getItem('角色管理', '/saas/businessmanagement/rolesmanagement'),
        ]
      ),
    ]
  }
  //企业版 其他成员
  return [
    getItem(
      '个人设置',
      '/saas/personalsettings',
      <MyIcon type="icon-gerenshezhi1" />
    ),
    // getItem(
    //   '账户管理',
    //   '/saas/accountmanagement',
    //   <MyIcon type="icon-zhanghuguanli1" />
    // ),
    getItem(
      '产品订阅',
      '/saas/productsubscription',
      <MyIcon type="icon-chanpindingyue" />
    ),
    getItem(
      '成员列表',
      '/saas/businessmanagement/membermanagement',
      <MyIcon type="icon-qiyeguanli1" />
    ),
  ]
}

export default function SaasLayout() {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  let pathname = location.pathname
  console.log(location)
  const { Header, Content, Sider } = Layout
  useEffect(() => {
    dispatch(getUserInfo())
    dispatch(getMenuOfPersonal())
  }, [dispatch])

  const userInfo = useSelector((state) => state.login.userInfo)
  const { personalMenus, licenseName } = useSelector((state) => state.login)
  console.log(userInfo)
  const onCheckPage = (e) => {
    history.push(e.key)
  }
  const confirm = () => {
    let redirect = window.location.origin + '/loginHtml'
    if (getCallbackUrl()) {
      redirect = getCallbackUrl()
    }
    request
      .post('sys/logout/', {
        redirect: redirect,
        saas: getDeploy() === 'BOX' ? undefined : true,
      })
      .then((res) => {
        if (res.data.data.status === 'redirect') {
          if (
            window.location.pathname === '/home/noProject' ||
            window.location.pathname === '/home/nofunc' ||
            window.location.pathname === '/home/guidePage'
          ) {
            setRedirectUrl(getLocationOrigin() + '/home')
          } else {
            setRedirectUrl(window.location.href)
          }
          if (res.data.data.logout_url) {
            window.location.href = res.data.data.logout_url
          } else {
            window.location.reload()
          }
          removeToken()
          removeUserStatus()
          removeSuperGroup()
          removeCompany()
          removeOaOrg()
        }
      })
    message.success('退出成功')
  }

  //切换企业成功回调
  const switchEnterpriseSuccessFunc = (data) => {
    setToken(data?.data.token || '')
    // setSuperGroup(data?.data.super_group || false)
    if (
      window.location.pathname === '/home/noProject' ||
      window.location.pathname === '/home/nofunc' ||
      window.location.pathname === '/home/guidePage'
    ) {
      window.location.href = getLocationOrigin() + '/home'
      // history.push('/home')
      return
    }
    window.location.reload()
  }

  const menuClickCallBack = (item) => {
    if (item.menu_name === '帮助中心') {
      window.open(item.menu_url + '系统门户', item?.menu_name)
      return true
    }
  }

  return (
    <div className={styles.root}>
      <Layout className="saas_layout">
        <MyHeader
          logoDom={
            <div style={{ display: 'flex', width: '100%' }}>
              <div className="goback_btn">
                <MyIcon
                  onClick={() => {
                    //跳回之前的界面
                    let url = getCallbackUrl()
                    if (url) {
                      window.location.href = url
                    } else {
                      //如果 有企业跳到首页

                      history.push('/home')
                    }
                  }}
                  type="icon-fanhui"
                  style={{ fontSize: 22, fontWeight: 800 }}
                />
              </div>
              <div className="logo">
                <img src={localStorage.getItem('logo_url') || logo} alt="" />
              </div>
            </div>
          }
          showUserMenusMinLength={0}
          userInfo={userInfo}
          personalMenus={personalMenus}
          userMenus={[]}
          logoutBack={confirm}
          getUnreadNoticesFunc={api.getUnreadNotices}
          getNoticesFunc={api.getNotices}
          setReadNoticesFunc={api.setReadNotices}
          deleteNoticesFunc={api.deleteNotices}
          getUpdateLogList={api.getUpdateLogList}
          editCompName={api.editCompName}
          getEnterpriseFunc={api.getEnterprise}
          validationFunc={(res) => res.data?.code === 0 || res.code === 0}
          switchEnterpriseFunc={api.switchEnterpriseById}
          switchEnterpriseCallBack={switchEnterpriseSuccessFunc}
          licenseName={licenseName}
          projectCode="SYS_MANAGE"
          menuClickCallBack={menuClickCallBack}
        ></MyHeader>
        {/* <Header>
          {location.pathname !== '/invite' && (
            <div className="goback_btn">
              <MyIcon
                onClick={() => {
                  history.push('/home')
                }}
                type="icon-fanhui"
                style={{ fontSize: 22, fontWeight: 800 }}
              />
            </div>
          )}

          <div className="right_info">
            <div className="logo">
              <img src={logo} alt="" />
            </div>
            <div>
              <span>{userInfo?.show_name}</span>
              <img
                style={{
                  borderRadius: '50%',
                  width: 28,
                  height: 28,
                  margin: '0px 24px 0px 8px',
                }}
                src={userInfo?.pf_photo}
                alt=""
              />
            </div>
          </div>
        </Header> */}
        <Layout>
          {location.pathname !== '/invite' && (
            <Sider
              trigger={null}
              width={216}
              style={{
                background: 'linear-gradient(223deg, #006bb3 0%, #15d2d6 100%)',
              }}
              className="site-layout-background"
              collapsible
            >
              <Menu
                onClick={onCheckPage}
                mode="inline"
                style={{ height: '100%', borderRight: 0 }}
                items={getItems(userInfo)}
                selectedKeys={[pathname]}
                defaultOpenKeys={['/saas/businessmanagement']}
              ></Menu>
            </Sider>
          )}

          <Content
            className="site-layout-background"
            id="right_content"
            style={{ position: 'relative' }}
          >
            <Switch key={location.key}>
              <Redirect
                exact
                from="/saas"
                to="/saas/personalsettings"
              ></Redirect>
              <Route
                path="/saas/businessmanagement/membermanagement"
                component={MemberManagement}
              ></Route>
              <Route
                path="/saas/businessmanagement/memberapplication"
                component={MemberApplication}
              ></Route>
              <Route
                path="/saas/businessmanagement/rolesmanagement"
                component={RolesManagement}
              ></Route>
              <Route
                path="/saas/productsubscription"
                component={Productsubscription}
              ></Route>
              <Route path="/invite" component={ApplMemberPage}></Route>
              <Route path="/saas/accountmanagement"></Route>
              <Route
                path="/saas/personalsettings"
                component={PersonalSettings}
              ></Route>
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </div>
  )
}
