import request from '../../utils/request'
import {
  GETLOGINLOG,
  GETMENUOFPERSONAL,
  GETMENUS,
  GETOPTLOG,
  LOGIN,
  LOGOUT,
  REGISTERUSER,
  USERINFO,
} from '../constants'
import qs from 'qs'
import {
  removeAdmin,
  removeCompany,
  removeOaOrg,
  removerCreator,
  removeSuperGroup,
  removeToken,
  setAdmin,
  setCompany,
  setCreator,
  setLogoUrl,
  setOaOrg,
  setSuperGroup,
  setTitleName,
  setToken,
  getSuperGroup,
  getCompany,
  setUserStatus,
  getUserStatus,
  removeUserStatus,
  getDeploy,
  getCallbackUrl,
} from '../../utils/cookie'
import api from '../../api'
import { message } from 'antd'
import { judgingStatus } from '../../utils/judgingStatus'
import { getLocationOrigin } from '../../utils/util'

/**
 * 登入
 * @param {*} payload
 * @returns
 */
export const login = (useSaas) => {
  let url = document.location.href
  let ticket = ''
  let code = ''
  if (/ticket=(ST-[^#/?]+)/.test(url)) {
    ticket = /ticket=(ST-[^#/?]+)/.exec(url)[1]
  } else if (/code=([^#/?]+)/.test(url)) {
    code = /code=([^#/?]+)/.exec(url)[1]
  }
  let redirect = window.location.origin + window.location.pathname;
  //如果是saas前缀 要跳到对应的产品登录界面.
  if(useSaas){
    if(getCallbackUrl()){
      redirect = getCallbackUrl()
    }
  }
  let params = {}
  if (ticket !== '') {
    params = {
      ticket: ticket,
      redirect: redirect,
      saas: useSaas ? true : undefined,
    }
  } else {
    params = {
      code: code,
      redirect: redirect,
      saas: useSaas ? true : undefined,
    }
  }
  console.log(params)
  return async (dispatch) => {
    const res = await request.get('sys/login/', {
      params,
    })
    console.log(res)
    if (res.data.data.status === 'redirect') {
      window.location.href = res.data.data.login_url
      return false
    } else if (res.data.data.status === 'login') {
      const token = res.data.data.token || ''
      setToken(token)
      const super_group = res.data.data.super_group || false
      setSuperGroup(super_group)
      dispatch({
        type: LOGIN,
        payload: res.data.data,
      })
      return true
    }
  }
}
/**
 * 登出
 * @returns
 */
export const logout = () => {
  return async (dispatch) => {
    const res = await request.post('sys/logout/', {
      redirect: window.location.origin + window.location.pathname,
    })
    // console.log(res.data.data.logout_url)
    console.log(res)
    if (res.data.data.status === 'redirect') {
      window.location.href = res.data.data.logout_url
      removeToken()
      removeUserStatus()
      removeSuperGroup()
      removeCompany()
      removeOaOrg()
      removerCreator()
      removeAdmin()
    }

    dispatch({
      type: LOGOUT,
    })
  }
}

export const changePassword = (values) => {
  return async (dispatch) => {
    const res = await request.post('sys/password/change/', values)
    return res
  }
}

export const getMenus = (params) => {
  return async (dispatch) => {
    const res = await request.get('/sys/user/menu/')
    console.log(res,params)
    //不需要校验
    if(params?.not_check){
      dispatch({
        type: GETMENUS,
        payload: res.data.data,
      })
      return
    }
    if (res.data?.data?.length === 0) {
      console.log(getDeploy(), 'getDeploy()-------->')
      if (getDeploy() === 'BOX') {
        console.log(getDeploy(), 'getDeploy()=======>')
        window.location.href = getLocationOrigin() + '/home/nosubScribe'
        return
      }
      if (
        window.location.pathname !== '/saas/noProject' &&
        window.location.pathname !== '/saas/guidePage' &&
        window.location.pathname !== '/home/nofunc'
      ) {
        // console.log(window.location,'---------');
        window.location.href =getLocationOrigin() + '/home/nofunc'
      }
    } else {
      if (!res.data.data?.some((item) => item.menu_code === 'SYS_MANAGE')) {
        const findItem = res.data.data?.find((i) => i.menu_url)
        if (findItem) {
          // window.location.href = findItem.menu_url
          if (getDeploy() === 'BOX') {
            if(window.location.pathname !== '/home/nosubScribe'){
              window.location.href = getLocationOrigin() + '/home/nosubScribe'
            }
          }else{
            if (
              window.location.pathname !== '/saas/guidePage'
            ) {
              window.location.href = getLocationOrigin() + '/saas/guidePage?project=SYS&fromLogin=true'
            }
          }
          
        } else {
          if (
            window.location.pathname !== '/home/nofunc'
          ) {
            window.location.href = getLocationOrigin() + '/home/nofunc'
          }
        }
      }
    }
    dispatch({
      type: GETMENUS,
      payload: res.data.data,
    })
  }
}

export const getUserInfo = () => {
  return async (dispatch) => {
    const res = await request.get('sys/user/info/')
    console.log(res.data)
    if (res.data.code === 0) {
      let createSuccess = false
      setCreator(res.data.data.is_creator)
      setAdmin(res.data.data.is_admin)
      const status = judgingStatus(res.data.data.status)
      //没有企业id 但是状态为绑定企业未开通服务或者正常
      if (!res.data.data.co_id && (status === 0 || status === 1)) {
        createSuccess = true
      } else if (
        (status === 0 || status === 1) &&
        (getUserStatus() === '2' || getUserStatus() === '3')
      ) {
        //新状态为未开通服务或者正常 旧状态为未绑定企业和审核中
        createSuccess = true
      }
      setUserStatus(status)
      setCompany(res.data?.data?.co_id || '')
      //审核成功 切换成已有企业
      if (createSuccess) {
        api.getEnterprise().then((res) => {
          if (res?.code === 0 && res.data?.length >= 1) {
            api.switchEnterpriseById(res.data?.at(0)?.co_id).then((res2) => {
              console.log(res2?.data?.data)
              if (res2.data.code === 0) {
                setToken(res2?.data?.data?.token || '')
                setSuperGroup(res2?.data?.data?.super_group || false)
                window.location.reload()
              } else {
                message.error(res?.data?.msg || '切换失败')
              }
            })
          } else {
            message.error(res.msg)
          }
        })
      }
    }
    dispatch({
      type: USERINFO,
      payload: res.data.data,
    })
  }
}

export const getLoginLog = (params) => {
  return async (dispatch) => {
    const res = await request.get('sys/log/login/', { params })
    dispatch({
      type: GETLOGINLOG,
      payload: res.data.data,
    })
  }
}

export const getOptLog = (params) => {
  return async (dispatch) => {
    const res = await request.get('sys/log/opt/', { params })
    // console.log(res.data.data)
    dispatch({
      type: GETOPTLOG,
      payload: res.data.data,
    })
  }
}

export const registerUser = (values) => {
  return async (dispatch) => {
    const res = await request.post('sys/registration/', values)
    dispatch({
      type: REGISTERUSER,
      payload: res.data.data,
    })
    return res
  }
}

export const exportsLog = (params, isLoading) => {
  return async (dispatch) => {
    return await request({
      url: 'sys/log/export/',
      params,
      method: 'get',
      responseType: 'blob',
      isLoading,
    })
  }
}

export const getMenuOfPersonal = (isLoading) => {
  return async (dispatch) => {
    const res = await request.get('sys/menu/personal/', { isLoading })
    dispatch({
      type: GETMENUOFPERSONAL,
      payload: res.data.data,
    })
  }
}
